import { Grid } from '@chakra-ui/react'

import {
  ArticleEanText,
  ArticleNumberText,
  CustomerArticleNumberText,
  DeliveryTime,
  StockInfo,
} from 'app/pages/common/marketplaceAndSearch/components'
import { EClassInfo } from 'app/pages/common/eClassInfo'

import type { ArticleListDetailItemCellType } from '../../types'

import { ExternalReferencesText } from './ExternalReferencesText'

type ArticleContentProps = Pick<
  ArticleListDetailItemCellType['value']['articleData'],
  'article' | 'externalReferences'
>

export const ArticleContent = ({
  article,
  externalReferences,
}: ArticleContentProps) => (
  <Grid
    templateColumns="repeat(4, minmax(auto, 200px))"
    gap="4px"
    columnGap="16px"
  >
    <ArticleNumberText number={article.number} />
    <CustomerArticleNumberText
      customerArticleNumber={article.customerArticleNumber}
    />
    <DeliveryTime
      deliveryTime={article.deliveryTime}
      deliveryInfo={article.deliveryInfo}
    />
    <ExternalReferencesText externalReferences={externalReferences} />
    <EClassInfo eclass={article.eclass} />
    <ArticleEanText ean={article.ean} />
    <StockInfo stockInfo={article.stockInfo} />
  </Grid>
)

import { Box } from '@chakra-ui/react'

import { DeliveryTime, StockInfo } from '../../ArticleTexts'
import type { ArticleContentProps } from '../types'

export const GridViewArticleDetails = ({
  article,
}: Pick<ArticleContentProps, 'article'>) => (
  <Box mt="16px">
    <DeliveryTime
      deliveryTime={article.deliveryTime}
      deliveryInfo={article.deliveryInfo}
    />
    <StockInfo stockInfo={article.stockInfo} />
  </Box>
)

import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Html5QrcodeScannerState } from 'html5-qrcode'

import { translations } from 'locales/translations'

import { Typography } from 'app/components/Typography'

import { MOBILE_SCANNER_TARGET_TAG_ID } from '../../consts'
import type { CodeScanActions, MobileScannerProps } from '../../types'

import { NoVideoPermissionWrapper, Scanner } from './styles'
import { ScanInfo } from './components'

const messages = translations.pages.customer.scanner.mobile

export type ScanningAreaProps = {
  progressButton?: React.ReactNode
} & Pick<
  CodeScanActions,
  'scannerState' | 'startScanning' | 'videoPermission'
> &
  Pick<
    MobileScannerProps,
    | 'isScannedValueProcessedSuccessfully'
    | 'scannerReadyMessage'
    | 'scannedSuccessfullyMessage'
  >

export const ScanningArea = ({
  scannerState,
  startScanning,
  videoPermission,
  isScannedValueProcessedSuccessfully,
  progressButton,
  scannerReadyMessage,
  scannedSuccessfullyMessage,
}: ScanningAreaProps) => {
  const { t } = useTranslation()

  const hasBeenScannedAndProcessedSuccessfully =
    isScannedValueProcessedSuccessfully &&
    scannerState === Html5QrcodeScannerState.PAUSED

  if (!videoPermission)
    return (
      <NoVideoPermissionWrapper>
        <Typography textStyle="textLargeSb" color="white">
          {t(messages.noCameraAccess.header)}
        </Typography>
        <Typography color="white">
          {t(messages.noCameraAccess.description)}
        </Typography>
      </NoVideoPermissionWrapper>
    )

  return (
    <Box position="relative">
      <Scanner
        ref={startScanning}
        id={MOBILE_SCANNER_TARGET_TAG_ID}
        hasBeenScannedAndProcessedSuccessfully={
          hasBeenScannedAndProcessedSuccessfully
        }
      />
      <ScanInfo>
        {hasBeenScannedAndProcessedSuccessfully
          ? (scannedSuccessfullyMessage ?? '')
          : scannerReadyMessage}
      </ScanInfo>

      {progressButton}
    </Box>
  )
}

import type { UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import { api } from 'apiServices'

import { useCurrentUser } from 'app/containers/common/Auth'

import { cartItemsScope } from '../../consts'
import type { CartItemId } from '../../models'

export type MODEL__CartItemLight = {
  articleId: string
  cartItemId: CartItemId
  inCartQuantity: number
}

export type API__CartItemsLightResponse = {
  items: MODEL__CartItemLight[]
}

export const url = cartItemsScope

const cartItemsLightRequest = () =>
  api.get<unknown, API__CartItemsLightResponse>(url)

export const CART_ITEMS_LIGHT_QUERY_KEY = 'cartItemsLight'

export const useCartItemsLightQuery = ({
  enabled = true,
}: { enabled?: boolean } = {}) => {
  const { isVendor } = useCurrentUser()

  return useQuery({
    queryKey: [CART_ITEMS_LIGHT_QUERY_KEY],
    queryFn: cartItemsLightRequest,
    enabled: !isVendor && enabled,
    select: (data) => data.items,
    staleTime: 3 * 1000,
  })
}

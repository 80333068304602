import { extendTheme, useTheme as useThemeOriginal } from '@chakra-ui/react'

import { textStyles } from 'app/components/Typography/textStyles'

import { colors } from './colors'
import { mediaQuery, breakpoints as numericBreakpoints } from './mediaQueries'
import { dimensionsRaw, dimensions } from './dimensions'
import { globalStyles } from './globalStyles'

const borders = {
  primary: `1px solid ${colors.line}`,
  white_15_alpha: `1px solid ${colors.white_15_alpha}`,
} as const

const shadows = {
  primary: `0px 16px 24px -6px ${colors.boxShadow}`,
  div: `0px 1px 2px 0px ${colors.divShadow}`,
  tag: `0px 1px 2px 0px ${colors.tagShadow}`,
} as const

const breakpoints = {
  sm: `${numericBreakpoints.small}px`,
  md: `${numericBreakpoints.medium}px`,
  lg: `${numericBreakpoints.large}px`,
  xl: `${numericBreakpoints['xl']}px`,
}

const zIndexes = {
  aboveGround: 5,
  basic: 10,
  header: 500,
  aboveModal: 1500,
} as const

const themeOverrides = {
  borders,
  breakpoints,
  colors,
  dimensionsRaw,
  dimensions,
  mediaQuery,
  shadows,
  styles: globalStyles,
  textStyles,
  zIndexes,
}

export type Theme = typeof themeOverrides

export const theme = extendTheme(themeOverrides) as Theme

export const useTheme = () => useThemeOriginal<Theme>()

import { faker } from '@faker-js/faker'

import type { API__NodeSelection } from 'libs/eClasses/renderUtils/types'

import type { VendorId } from 'app/models/user'
import type { ModelSearchResult } from 'app/containers/common/Search/types/response'
import type { CompanyUserId } from 'app/models/companyUser'
import { assignedAccountTypesMockData } from 'app/containers/customers/AccountTypes/AssignedToCustomer'
import { assignedAccountTypeValuesMockData } from 'app/containers/customers/AccountTypes/Values/AssignedToCustomer'
import { assignedGeneralLedgersMockData } from 'app/containers/customers/GeneralLedgers/AssignedToCustomer/mock/data'
import type { AccountTypeValueId } from 'app/containers/customers/AccountTypes/Values/models'
import type { ApprovalFlowId } from 'app/containers/customers/ApprovalFlows/models'

import type { CartItemId, MODEL__CartAccountType } from '../../models'
import type { API__CartResponse } from '../hooks'

export const articleDataMockData: ModelSearchResult = {
  article: {
    id: '0906cd7b-2810-4671-963b-81be91d2ad25',
    name: 'Drilling machine',
    number: 'ABC123456',
    customerArticleNumber: 'VENO.2211234',
    ean: '978020137962',
    eclass: {
      version: 'ECLASS_5_1',
      code: '28-07-02-01',
      name: 'Bike',
    },
    manufacturer: 'Bosch',
    thumbnailUrl: 'https://simplesystem.com/images/1234.jpg',
    deliveryTime: 1,
    deliveryInfo: {
      days: 1,
      source: 'STOCK_AND_DELIVERY_INFO',
      updatedAt: '2024-08-22T17:32:28.682Z',
    },
    stockInfo: { value: 'IN_STOCK', updatedAt: '2024-08-21T17:32:28.682Z' },
    minimumOrder: 1,
    coreArticle: true,
    closedMarketplace: true,
    explanation: undefined,
    tax: 0,
    orderUnit: 'crate',
    isIncludedInArticleList: false,
    orderOptions: {
      restricted: false,
      priceQuantity: '1',
      pricing: [{ quantity: '1', price: { amount: 1234, currency: 'GBP' } }],
    },
  },
  vendor: {
    id: 'V12345' as VendorId,
    name: 'Hoffmann Group',
  },
}

export const accountTypeAndValue: MODEL__CartAccountType = {
  typeId: assignedAccountTypesMockData.accountTypes[0].id,
  valueId: assignedAccountTypeValuesMockData.accountTypeValues[0].id,
  freeTextValue: undefined,
}

export const generateApprovalRuleData = () => ({
  approvalFlowId: faker.string.uuid() as ApprovalFlowId,
  mainApprover: {
    id: faker.string.uuid() as CompanyUserId,
    name: `${faker.person.firstName()} ${faker.person.lastName()}`,
  },
  deputyApprover: {
    id: faker.string.uuid() as CompanyUserId,
    name: `${faker.person.firstName()} ${faker.person.lastName()}`,
  },
})

export const cartResponseMockData: API__CartResponse = {
  items: [
    {
      vendor: {
        id: '022e31c7-c5ed-43d2-8690-994726e332dc' as VendorId,
        name: 'ACME Ltd.',
      },
      items: [
        {
          id: '12314234534643214' as CartItemId,
          immutable: false,
          articleData: articleDataMockData,
          quantity: 3,
          total: { amount: 1234, currency: 'GBP' },
          priceUsed: { amount: 1234, currency: 'GBP' },
          account: null,
          generalLedgerId: null,
          defaultGeneralLedger: null,
          itemComment: 'Lots of onions and potatoes, please',
          additionalAccountingInfo: 'Custom additional message',
        },
        {
          id: '02314234534643210' as CartItemId,
          immutable: false,
          articleData: articleDataMockData,
          quantity: 1,
          total: { amount: 1111, currency: 'GBP' },
          priceUsed: { amount: 1111, currency: 'GBP' },
          account: null,
          generalLedgerId: null,
          defaultGeneralLedger: null,
          additionalAccountingInfo: null,
        },
        {
          id: 'x231423453464321x' as CartItemId,
          immutable: false,
          articleData: {
            ...articleDataMockData,
            article: {
              ...articleDataMockData.article,
              id: 'x906cd7b-2810-4671-963b-81be91d2ad2x',
            },
          },
          quantity: 2,
          total: { amount: 2222, currency: 'GBP' },
          priceUsed: { amount: 2222, currency: 'GBP' },
          account: null,
          generalLedgerId: null,
          defaultGeneralLedger: null,
          additionalAccountingInfo: null,
        },
        {
          id: 'y231423453464321y' as CartItemId,
          immutable: false,
          articleData: {
            ...articleDataMockData,
            article: {
              ...articleDataMockData.article,
              id: 'y906cd7b-2810-4671-963b-81be91d2ad2y',
            },
          },
          quantity: 3,
          total: { amount: 3333, currency: 'GBP' },
          priceUsed: { amount: 3333, currency: 'GBP' },
          account: null,
          generalLedgerId: null,
          defaultGeneralLedger: null,
          additionalAccountingInfo: null,
        },
      ],
      total: { amount: 1234, currency: 'GBP' },
      desiredDeliveryDate: '2023-08-08',
      customOrderNumber: null,
      vendorCartInfo:
        'DHL Express does not accept international shipments of Controlled Military Items subject to export controls.',
    },
    {
      vendor: {
        id: '022e31c7-c5ed-43d2-8690-994726e332d0' as VendorId,
        name: 'live Ltd.',
      },
      items: [
        {
          id: '23975548736239045834985' as CartItemId,
          immutable: true,
          articleData: {
            article: {
              id: '0906cd7b-2810-4671-963b-81be91d2ad26',
              name: 'A machine',
              number: 'ABC123456',
              customerArticleNumber: 'VENO.2211235',
              ean: 'EAN123457',
              manufacturer: 'Makita',
              thumbnailUrl: 'https://simplesystem.com/images/1234.jpg',
              deliveryTime: 2,
              deliveryInfo: {
                days: 2,
                source: 'STOCK_AND_DELIVERY_INFO',
                updatedAt: '2024-08-22T17:32:28.682Z',
              },
              stockInfo: {
                value: 'IN_STOCK',
                updatedAt: '2024-08-21T17:32:28.682Z',
              },
              minimumOrder: 5,
              coreArticle: true,
              closedMarketplace: true,
              explanation: undefined,
              tax: 0,
              orderUnit: 'crate',
              isIncludedInArticleList: false,
              orderOptions: {
                restricted: false,
                priceQuantity: '1',
                pricing: [
                  { quantity: '1', price: { amount: 3322, currency: 'GBP' } },
                ],
              },
            },
            vendor: {
              id: 'V12345' as VendorId,
              name: 'Hoffmann Group',
            },
          },
          quantity: 5,
          total: { amount: 3322, currency: 'GBP' },
          priceUsed: { amount: 1234, currency: 'GBP' },
          account: null,
          generalLedgerId: null,
          defaultGeneralLedger: null,
          itemComment: 'No comment',
          additionalAccountingInfo: null,
        },
        {
          id: '423523443654745234234' as CartItemId,
          immutable: false,
          articleData: {
            article: {
              id: '0206cd7b-2810-4671-963b-81be91d2ad20',
              name: 'B machine',
              number: 'ABC123456',
              customerArticleNumber: 'VENO.2211236',
              ean: 'EAN123458',
              manufacturer: 'Makita',
              thumbnailUrl: 'https://simplesystem.com/images/1234.jpg',
              deliveryTime: 3,
              deliveryInfo: {
                days: 3,
                source: 'CATALOG',
                updatedAt: null,
              },
              stockInfo: {
                value: 'NOT_PROVIDED',
                updatedAt: null,
              },
              minimumOrder: 1,
              coreArticle: true,
              closedMarketplace: true,
              explanation: undefined,
              tax: 0,
              orderUnit: 'crate',
              isIncludedInArticleList: false,
              orderOptions: {
                restricted: false,
                priceQuantity: '1',
                pricing: [
                  { quantity: '1', price: { amount: 1111, currency: 'GBP' } },
                ],
              },
            },
            vendor: {
              id: 'V12345' as VendorId,
              name: 'Hoffmann Group',
            },
          },
          quantity: 2,
          total: { amount: 1111, currency: 'GBP' },
          priceUsed: { amount: 1234, currency: 'GBP' },
          account: null,
          generalLedgerId: null,
          defaultGeneralLedger: null,
          additionalAccountingInfo: null,
        },
      ],
      total: { amount: 4433, currency: 'GBP' },
      desiredDeliveryDate: null,
      customOrderNumber: null,
      vendorCartInfo: null,
    },
  ],
  total: { amount: 1234, currency: 'GBP' },
  unavailableArticles: [],
  account: accountTypeAndValue,
  generalLedgerId: assignedGeneralLedgersMockData.generalLedgers[0].id,
  requiredApprovals: [
    {
      ...generateApprovalRuleData(),
      rule: {
        template: 'ARTICLE_PRICE_BIGGER_THAN',
        articlePriceBiggerThanRuleConfiguration: {
          priceGreaterThan: { currency: 'GBP', amount: 1000 },
        },
      },
    },
    {
      ...generateApprovalRuleData(),
      rule: {
        template: 'ORDER_TOTAL_BIGGER_THAN',
        orderTotalBiggerThanSimpleRuleConfiguration: {
          totalGreaterThan: { currency: 'GBP', amount: 12345 },
        },
      },
    },
    {
      ...generateApprovalRuleData(),
      rule: {
        template: 'VENDOR_IS_ANY_OF',
        vendorIsAnyOfRuleConfiguration: {
          selectionType: 'ALL_VENDORS',
        },
      },
    },
    {
      ...generateApprovalRuleData(),
      rule: {
        template: 'VENDOR_IS_ANY_OF',
        vendorIsAnyOfRuleConfiguration: {
          selectionType: 'ALL_VENDORS',
        },
      },
    },
    {
      ...generateApprovalRuleData(),
      rule: {
        template: 'ACCOUNT_VALUE_IS_ANY_OF',
        accountValueIsAnyOfRuleConfiguration: {
          accountValueIds: [
            'cb50273a-5200-44d4-80e1-3a15ad1b414c',
            'cb50273a-5200-44d4-80e1-3a950d1b414e',
            'cb50273a-5200-44d4-80e1-3a14fd1b4133',
            'cb50273a-5200-44d4-80e1-1a150d1b414d',
          ] as AccountTypeValueId[],
        },
      },
    },
    {
      ...generateApprovalRuleData(),
      rule: {
        template: 'ARTICLE_E_CLASS_ANY_OF',
        articleEClassAnyOfConfiguration: {
          eClasses: {
            '17-00-00-00': true,
            '17-02-00-00': false,
            '18-00-00-00': true,
            '19-00-00-00': true,
            '20-00-00-00': true,
            '24-00-00-00': true,
            '25-00-00-00': true,
          } as API__NodeSelection,
        },
      },
    },
  ],
  customOrderNumberSequenceExhausted: false,
  accountTypeAndValueMandatory: true,
  generalLedgerMandatory: true,
  additionalAccountingInfo: 'Cart general accounting info',
  orderComment: 'Please deliver this before 10am',
}

export const emptyCartResponseMockData: API__CartResponse = {
  ...cartResponseMockData,
  orderComment: '',
  items: [],
  total: { amount: 0, currency: 'GBP' },
}

export const unavailableArticlesMockData = [
  {
    id: '23975548736876876876985' as CartItemId,
    immutable: true,
    articleData: {
      article: {
        id: '0490jfb-2810-4671-963b-849jjkbd26',
        name: 'Unavailable Machine',
        number: 'AV89273546',
        ean: 'EAN1903843',
        manufacturer: 'MKJSON',
        thumbnailUrl: 'https://simplesystem.com/images/1234.jpg',
        deliveryTime: 4,
        minimumOrder: 5,
        coreArticle: true,
        closedMarketplace: true,
        explanation: undefined,
        tax: 0,
        orderUnit: 'crate',
        isIncludedInArticleList: false,
        orderOptions: {
          restricted: false,
          priceQuantity: '1',
          pricing: [
            { quantity: '1', price: { amount: 3500, currency: 'GBP' } },
          ],
        },
      },
      vendor: {
        id: 'V98235' as VendorId,
        name: 'Hoffmann Group',
      },
    },
    quantity: 5,
    total: { amount: 3500, currency: 'GBP' },
    priceUsed: { amount: 1234, currency: 'GBP' },
    account: null,
    generalLedgerId: null,
    defaultGeneralLedger: null,
    itemComment: 'No comment',
    additionalAccountingInfo: null,
  },
  {
    id: '2397554886513376876943' as CartItemId,
    immutable: true,
    articleData: {
      article: {
        id: '0490jfb-2810-4671-963b-849sdjnlgd54dsf',
        name: 'Unavailable Article',
        number: 'AV89273648',
        ean: 'EAN1903697',
        manufacturer: 'MKJJA',
        thumbnailUrl: 'https://simplesystem.com/images/1234.jpg',
        deliveryTime: 4,
        minimumOrder: 5,
        coreArticle: true,
        closedMarketplace: true,
        explanation: undefined,
        tax: 0,
        orderUnit: 'crate',
        isIncludedInArticleList: false,
        orderOptions: {
          restricted: false,
          priceQuantity: '1',
          pricing: [
            { quantity: '1', price: { amount: 2500, currency: 'GBP' } },
          ],
        },
      },
      vendor: {
        id: 'V98235' as VendorId,
        name: 'Hoffmann Group',
      },
    },
    quantity: 5,
    total: { amount: 2500, currency: 'GBP' },
    priceUsed: { amount: 1324, currency: 'GBP' },
    account: null,
    generalLedgerId: null,
    defaultGeneralLedger: null,
    itemComment: 'No comment',
    additionalAccountingInfo: null,
  },
  {
    id: '2397554881099346876943' as CartItemId,
    immutable: true,
    articleData: {
      article: {
        id: '0490jfb-2810-4671-963b-849sdjnlgd54dsf',
        name: 'Unavailable Article',
        number: 'AV89273648',
        ean: 'EAN1903697',
        manufacturer: 'MKJJA',
        thumbnailUrl: 'https://simplesystem.com/images/1234.jpg',
        deliveryTime: 4,
        minimumOrder: 5,
        coreArticle: true,
        closedMarketplace: true,
        explanation: undefined,
        tax: 0,
        orderUnit: 'crate',
        isIncludedInArticleList: false,
        orderOptions: {
          restricted: false,
          priceQuantity: '1',
          pricing: [
            { quantity: '1', price: { amount: 2500, currency: 'GBP' } },
          ],
        },
      },
      vendor: {
        id: 'V98235' as VendorId,
        name: 'Hoffmann Group',
      },
    },
    quantity: 5,
    total: { amount: 2500, currency: 'GBP' },
    priceUsed: { amount: 1324, currency: 'GBP' },
    account: null,
    generalLedgerId: null,
    defaultGeneralLedger: null,
    itemComment: 'No comment',
    additionalAccountingInfo: null,
  },
]

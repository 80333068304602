import type {
  ModelArticleDetailData,
  ModelArticleDetailError,
} from 'libs/openapiGeneratedApi/Api'

import type { VendorId } from 'app/models/user'

import type { ModelOrderOptions } from '../../Search'

export const articleDetailsMockedResponse: ModelArticleDetailData = {
  id: '4efac64c-0920-4f8f-bda1-03b0749c3619',
  name: 'Bürolocher Office Depot 2-fach 20 Blatt Schwarz',
  number: '1234',
  customerArticleNumberInfo: {
    value: '9999999999999',
    isEdited: true,
    defaultValue: '840021357',
  },
  ean: '2159407467111',
  eclass: {
    version: 'ECLASS_5_1',
    code: '28-07-02-01',
    name: 'Bike',
  },
  manufacturer: 'Office Depot',
  deliveryTime: 1,
  deliveryInfo: {
    days: 1,
    source: 'STOCK_AND_DELIVERY_INFO',
    updatedAt: '2024-08-22T17:32:28.682Z',
  },
  stockInfo: { value: 'IN_STOCK', updatedAt: '2024-08-21T17:32:28.682Z' },
  description:
    'The <i>office punch 91W0</i> from <b>Office Depot</b> in black and blue should definitely not be missing on your desk. You can punch up to 20 sheets at a time with lightning-fast and easy, without having to use a lot of force. The hole punch is made of metal and has a plastic cap, which provides more comfort when punching.<br/><br/>\n' +
    'Do you also find it annoying when a sheet of paper is not precisely punched and then corners of the paper in a folder or loose-leaf binder protrude and bend? Thanks to the practical stop rail, with which you can determine exactly which format you are currently punching, this will no longer be a problem in the future. This Office Depot hole punch has 2 holes with a gap of 80 mm, and allows your documents to fit into almost any folder.',
  hsCode: 'hsCode',
  manufacturerArticleNumber: '45678',
  manufacturerArticleType: 'xyz',
  vendorRemarks: 'remark from vendor',
  quantityInterval: 5,
  contentUnit: 'PCE',
  contentAmount: '100',
  dependent: false,
  expired: false,
  coreArticle: true,
  closedMarketplace: true,
  tax: 0,
  features: [
    { name: 'Stop rail', values: ['Yes'] },
    { name: 'Base color', values: ['Black'] },
    { name: 'Dimensions', values: ['9.5 (W) x 9.7 (D) x 9.1 (H) cm'] },
    {
      name: 'Dimensions in single values',
      values: ['9.5 (W)', '9.7 (D)', '9.1 (H)'],
      unit: 'cm',
    },
    { name: 'Punch Capacity (Sheets)', values: ['20'], unit: 'Sheets' },
    {
      name: 'Big description',
      values: [
        'Some very very long value/description, just to see if we render it properly into multiline and not overlap with other rows. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor lectus justo, vel dapibus velit blandit eu. Nam porttitor massa sed ligula molestie interdum.',
      ],
    },
  ],
  minimumOrder: 2,
  orderUnit: 'PK',
  orderOptions: {
    restricted: false,
    priceQuantity: '1',
    pricing: [
      {
        quantity: '1',
        price: {
          currency: 'GBP',
          amount: 1234,
        },
        source: {
          type: 'PRICE_LIST',
          priceListFilename: 'price_list_file.csv',
        },
      },
    ],
  },
  isIncludedInArticleList: false,

  vendor: {
    name: 'Acme Ltd',
    id: '9c99a19c-19a5-471d-951a-cfbd6f980439' as VendorId,
  },
  vendorContact: {
    title: 'MR',
    firstName: 'Vendor1',
    lastName: 'Name1',
    phoneNumber: '+99 000010001',
    email: 'fake@email.com',
  },
  additionalMedia: [
    {
      url: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf',
      description: '1st PDF file description',
      fileType: 'PDF',
    },
    {
      url: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf',
      description: '2nd TXT file description',
      fileType: 'TXT',
    },
  ],
  images: [
    {
      thumbnail: 'https://picsum.photos/seed/picsum1/70/70',
      pageDisplay: 'https://picsum.photos/seed/picsum1/500/500',
    },
    {
      thumbnail: 'https://picsum.photos/seed/picsum2/70/70',
      pageDisplay: 'https://picsum.photos/seed/picsum2/500/700',
    },
    {
      thumbnail: 'https://picsum.photos/seed/picsum3/70/70',
      pageDisplay: 'https://picsum.photos/seed/picsum3/500/700',
    },
    {
      thumbnail: 'https://picsum.photos/seed/picsum4/70/70',
      pageDisplay: 'https://picsum.photos/seed/picsum4/700/500',
    },
    {
      thumbnail: 'https://picsum.photos/seed/picsum5/70/70',
      pageDisplay: 'https://picsum.photos/seed/picsum5/500/700',
    },
    {
      thumbnail: 'https://picsum.photos/seed/picsum6/70/70',
      pageDisplay: 'https://picsum.photos/seed/picsum6/500/700',
    },
    {
      thumbnail: 'https://picsum.photos/seed/picsum7/70/70',
      pageDisplay: 'https://picsum.photos/seed/picsum7/500/700',
    },
    {
      thumbnail: 'https://picsum.photos/seed/picsum8/70/70',
      pageDisplay: 'https://picsum.photos/seed/picsum8/500/700',
    },
    {
      thumbnail: 'https://picsum.photos/seed/picsum9/70/70',
      pageDisplay: 'https://picsum.photos/seed/picsum9/700/500',
    },
    {
      thumbnail: 'https://picsum.photos/seed/picsum10/70/70',
      pageDisplay: 'https://picsum.photos/seed/picsum10/500/500',
    },
    {
      thumbnail: 'https://picsum.photos/seed/picsum11/70/70',
      pageDisplay: 'https://picsum.photos/seed/picsum11/700/500',
    },
  ],
}

export const articleDetails404ErrorMockedResponse: ModelArticleDetailError = {
  message:
    "Article 'ArticleId(vendorId=e7ee7a16-9122-4573-ae8c-a4fc5012e10b, articleNumber=1695352-5K\b!)' does not exist",
  details: [],
  timestamp: '2024-03-12T17:19:55.679087Z',
}

export const orderOptionsWithBulkPrices: ModelOrderOptions = {
  restricted: false,
  priceQuantity: '1',
  pricing: [
    {
      quantity: '1',
      price: {
        currency: 'GBP',
        amount: 499,
      },
    },
    {
      quantity: '3',
      price: {
        currency: 'GBP',
        amount: 489,
      },
    },
    {
      quantity: '5',
      price: {
        currency: 'GBP',
        amount: 449,
      },
    },
    {
      quantity: '10',
      price: {
        currency: 'GBP',
        amount: 399,
      },
    },
  ],
}

import type { ValueOf } from 'type-fest'

export const breakpoints = {
  small: 800,
  medium: 1020,
  large: 1440,
  xl: 1600,
} as const

export const bareMediaQuery = {
  small: `(max-width: ${breakpoints.small}px)`,
  medium: `(max-width: ${breakpoints.medium}px)`,
  large: `(min-width: ${breakpoints.large}px)`,
  xl: `(min-width: ${breakpoints.xl}px)`,
} as const

export const mediaQuery = {
  small: `@media screen and ${bareMediaQuery.small}`,
  medium: `@media screen and ${bareMediaQuery.medium}`,
  large: `@media screen and ${bareMediaQuery.large}`,
  print: '@media print',
} as const

export type MediaQuery = ValueOf<typeof mediaQuery>

import { useTranslation } from 'react-i18next'

import { translations } from 'locales/translations'
import { STOCK_AND_DELIVERY_LISTS_ENABLED } from 'consts/envVars'
import { useTheme } from 'theme'

import { ShippingIcon } from 'app/components/icons'
import { Typography, type TypographyProps } from 'app/components/Typography'
import { useBreakpointScreenSize } from 'app/hooks/useBreakpointScreenSize'
import type { ModelSearchArticleItem } from 'app/containers/common/Search'
import { MarkerTooltip } from 'app/components/tooltips'
import { useGetTimeSince } from 'app/hooks/date/useGetTimeSince'

const messages = translations.pages.customer.search.searchArticle

type DeliveryTimeProps = TypographyProps & {
  deliveryTime?: number
  deliveryInfo?: ModelSearchArticleItem['deliveryInfo']
}

export const DeliveryTime = ({
  deliveryTime,
  deliveryInfo,
  ...rest
}: DeliveryTimeProps) => {
  const { t } = useTranslation()
  const { isMobile } = useBreakpointScreenSize()
  const { colors } = useTheme()
  const getTimeSince = useGetTimeSince()

  if (STOCK_AND_DELIVERY_LISTS_ENABLED && deliveryInfo) {
    const isSourceFromDeliveryInfoFile =
      deliveryInfo.source === 'STOCK_AND_DELIVERY_INFO'

    const tooltipContent =
      isSourceFromDeliveryInfoFile && deliveryInfo.updatedAt
        ? t(messages.deliveryTimeTooltip.infoFile, {
            timeSince: getTimeSince(deliveryInfo.updatedAt),
          })
        : t(messages.deliveryTimeTooltip.catalog)

    return (
      <MarkerTooltip content={tooltipContent}>
        <Typography
          textStyle="textSmall"
          color="contentB"
          display="flex"
          gap="8px"
          alignItems="center"
          alignSelf="flex-start"
          {...rest}
        >
          <ShippingIcon
            {...(isSourceFromDeliveryInfoFile && { color: colors.green_600 })}
          />
          <span>
            {t(
              isMobile
                ? translations.words.days
                : messages.deliveryTimeInDaysFootnote,
              { count: deliveryInfo.days },
            )}
          </span>
        </Typography>
      </MarkerTooltip>
    )
  }

  if (deliveryTime) {
    return (
      <Typography
        color="contentB"
        display="flex"
        gap="4px"
        alignItems="center"
        alignSelf="flex-start"
        {...rest}
      >
        <ShippingIcon />

        <span>
          {t(
            isMobile
              ? translations.words.days
              : messages.deliveryTimeInDaysFootnote,
            { count: deliveryTime },
          )}
        </span>
      </Typography>
    )
  }

  return null
}

import type { LocationDescriptorObject } from 'history'
import type { ValueOf } from 'type-fest'

import type { Flatten } from 'types'

// DOUBLE CHECK BEFORE CHANGING EXISTING PATHS
// Some paths defined here are used outside the application (in Emails etc.)
export const PATHS = {
  root: '/',
  chooseCompanyType: '/choose-company-type',
  customerCompanyRegistration: '/customer-company-registration',
  customerRegistrationSuccess: '/customer-registration-success',
  vendorCompanyRegistration: '/vendor-company-registration',
  vendorRegistrationSuccess: '/vendor-registration-success',
  emailRedirect: '/email-redirect',
  selectAccount: '/select-account',
  oci401: '/oci401',
  error404: '/error404',
  passwordReset: {
    root: '/authentication/password-reset',
    passwordReset: '/authentication/password-reset/init',
    resetPasswordSuccess:
      '/authentication/password-reset/password-reset-success',
    newPassword: '/authentication/password-reset/:passwordResetId',
    newPasswordSuccess: '/authentication/password-reset/new-password-success',
    newPasswordFailure: '/authentication/password-reset/new-password-failure',
  },
  invitation: {
    root: '/invitation/:invitationKey',
    invitationSetPassword: '/invitation/:invitationKey/set-password',
    signedUpConfirmation: '/invitation/:invitationKey/signed-up',
    invitationExpired: '/invitation/:invitationKey/expired',
    invitationAccepted: '/invitation/:invitationKey/accepted',
    login: '/invitation/:invitationKey/login',
  },
  activation: {
    root: '/verification/:verificationKey',
    success: '/verification/processed/success',
    expired: '/verification/processed/expired',
    alreadyActivated: '/verification/processed/already-activated',
    notYetActivated: '/verification/processed/not-activated',
    invalidCode: '/verification/processed/invalid-code',
  },
  approveOrder: {
    root: '/approval-link/:linkId/approve',
    success: '/approval-link/:linkId/approve/success',
    alreadyApproved: '/approval-link/:linkId/approve/already-approved',
    expired: '/approval-link/:linkId/approve/expired',
    alreadyRejected: '/approval-link/:linkId/approve/already-rejected',
  },
  sso: {
    root: '/sso',
    success: '/sso/success',
    error: '/sso/error',
  },
  customer: {
    home: '/customer/home',
    articleDetails: '/customer/articles/:articleId',
    budgetLimits: '/customer/budget-limits',
    checkout: {
      root: '/customer/checkout-process',
      cart: '/customer/checkout-process/cart',
      address: '/customer/checkout-process/checkout-address',
    },
    checkoutSuccess: '/customer/checkout-success',
    // INFO: These 3 below should be inside of the companyAndSettings branch
    // but the paths need to be the same for the vendor and customer
    // and we don't have a separate settings page for the vendors
    // T0DO: What about the URLs - it should be companyUserId, not userId
    // https://simplesystem.atlassian.net/browse/S2NG-3018
    addUser: '/customer/users/add-new-user',
    // Rename :userId placeholder to :customerUserId https://simplesystem.atlassian.net/browse/S2NG-9472
    editUser: '/customer/users/:userId/edit',
    companyAndSettings: {
      root: '/customer/company-and-settings',
      myCompany: '/customer/company-and-settings/my-company',
      usersManagement: '/customer/company-and-settings/users-management',
      userDetails: '/customer/company-and-settings/users-management/:userId',
      assignUsersToUserGroup:
        '/customer/company-and-settings/users-management/user-group/:userGroupId/assign-users',
      deliveryAddresses: '/customer/company-and-settings/delivery-addresses',
      erpIntegration: '/customer/company-and-settings/erp-integration',
      createVendingMachine:
        '/customer/company-and-settings/vending-machines/create',
      editVendingMachine:
        '/customer/company-and-settings/vending-machines/:vendingMachineId/edit',
      vendingMachineDetails:
        '/customer/company-and-settings/vending-machines/:vendingMachineId',
      accounting: '/customer/company-and-settings/accounting',
      accountingSettings: '/customer/company-and-settings/accounting/settings',
      assignUsersToAccountType:
        '/customer/company-and-settings/accounting/account-type/:accountTypeId/assign-users',
      assignUsersToAccountValue:
        '/customer/company-and-settings/accounting/account-type/:accountTypeId/account-value/:valueId/assign-users',
      assignUsersToGeneralLedger:
        '/customer/company-and-settings/accounting/general-ledger/:generalLedgerId/assign-users',
      approvalFlows: '/customer/company-and-settings/approval-flows',
      approvalFlowsSettings:
        '/customer/company-and-settings/approval-flows/settings',
      addApprovalFlow: '/customer/company-and-settings/approval-flows/create',
      editApprovalFlow:
        '/customer/company-and-settings/approval-flows/:approvalFlowId/edit',
      assignUsersToApprovalFlow:
        '/customer/company-and-settings/approval-flows/:approvalFlowId/assign-users',
      shopperAccessControl:
        '/customer/company-and-settings/shopper-access-control',
      createPriceRestriction:
        '/customer/company-and-settings/shopper-access-control/price-restriction/create',
      createDefaultPriceRestriction:
        '/customer/company-and-settings/shopper-access-control/price-restriction/createDefault',
      editPriceRestriction:
        '/customer/company-and-settings/shopper-access-control/price-restriction/:priceRestrictionId/edit',
      editDefaultPriceRestriction:
        '/customer/company-and-settings/shopper-access-control/price-restriction/:priceRestrictionId/editDefault',
      assignUsersToPriceRestriction:
        '/customer/company-and-settings/shopper-access-control/price-restriction/:priceRestrictionId/assign-users',
      createCartRestriction:
        '/customer/company-and-settings/shopper-access-control/cart-restrictions/create',
      assignUsersToCartRestriction:
        '/customer/company-and-settings/shopper-access-control/cart-restrictions/:cartRestrictionId/assign-users',
      editCartRestriction:
        '/customer/company-and-settings/shopper-access-control/cart-restrictions/:cartRestrictionId/edit',
      createCatalogView:
        '/customer/company-and-settings/shopper-access-control/catalog-views/:catalogViewDraftId/create',
      editCatalogView:
        '/customer/company-and-settings/shopper-access-control/catalog-views/:catalogViewDraftId/edit',
      assignUsersToCatalogView:
        '/customer/company-and-settings/shopper-access-control/catalog-views/:catalogViewId/assign-users',
      createDefaultCatalogView:
        '/customer/company-and-settings/shopper-access-control/catalog-views/default/:catalogViewDraftId/create',
      editDefaultCatalogView:
        '/customer/company-and-settings/shopper-access-control/catalog-views/default/:catalogViewDraftId/edit',
      articleLists: '/customer/company-and-settings/article-lists',
      articleListDetails:
        '/customer/company-and-settings/article-lists/:articleListId',
      shareArticleList:
        '/customer/company-and-settings/article-lists/:articleListId/share',
      articleListsCollaborate:
        '/customer/company-and-settings/article-lists/:articleListId/collaborate',
      articleListGenerateBarcodes:
        '/customer/company-and-settings/article-lists/:articleListId/generate-barcodes',
      assignDeliveryAddress:
        '/customer/company-and-settings/delivery-addresses/:deliveryAddressId/assign',
      deliveryAddressManagePermissions:
        '/customer/company-and-settings/delivery-addresses/permissions',
      budgets: '/customer/company-and-settings/budgets',
      createBudget: '/customer/company-and-settings/budgets/create',
      editBudget: '/customer/company-and-settings/budgets/:budgetId/edit',
      deliveriesAndPayments:
        '/customer/company-and-settings/deliveries-and-payments',
      platformOrderDeliveriesAndPaymentsDetails:
        '/customer/company-and-settings/deliveries-and-payments/platform-orders/:platformOrderId',
      erpGeneratedOrderDeliveriesAndPaymentsDetails:
        '/customer/company-and-settings/deliveries-and-payments/erp-generated-orders/:erpOrderId',
      articleIndividualisation:
        '/customer/company-and-settings/article-individualisation',
      createCustomerArticleNumbersList:
        '/customer/company-and-settings/article-individualisation/customer-article-numbers-list/create',
      editCustomerArticleNumbersList:
        '/customer/company-and-settings/article-individualisation/customer-article-numbers-list/edit',
      createCoreArticleList:
        '/customer/company-and-settings/article-individualisation/core-article-list/create',
      editCoreArticleList:
        '/customer/company-and-settings/article-individualisation/core-article-list/edit',
      articleIndividualisationUserPrivileges:
        '/customer/company-and-settings/article-individualisation/user-privileges',
      deliveriesAndPaymentsManageUserPrivileges:
        '/customer/company-and-settings/deliveries-and-payments/user-privileges',
      createIndividualMapping:
        '/customer/company-and-settings/individual-mapping/create',
      editIndividualMapping:
        '/customer/company-and-settings/individual-mapping/:ociFieldsMappingId/edit',
      reports: '/customer/company-and-settings/reports',
    },
    orders: '/customer/order-history',
    platformOrderDetails: '/customer/platform-orders/:platformOrderId',
    ociTransferredCartDetails:
      '/customer/oci-transferred-carts/:ociTransferredCartId',
    erpGeneratedOrderDetails: '/customer/erp-generated-orders/:erpOrderId',
    ordersManageUserPrivileges: '/customer/order-history/user-privileges',
    requestNewVendor: '/customer/vendors/request-new-vendor',
    requestNewVendorSuccess: '/customer/vendors/request-new-vendor-success',
    root: '/customer',
    ociSessionRoot: '/customer-oci-session/:sessionId',
    search: '/customer/search',
    ociCatalogs: '/customer/search/oci-catalogs',
    vendors: '/customer/vendors',
    switchAccount: '/customer/switch-account',
    scanner: '/customer/scanner',
    fastOrder: '/customer/fast-order',
  },
  vendor: {
    root: '/vendor',
    home: '/vendor/home',
    catalogs: '/vendor/catalogs',
    addCatalog: '/vendor/catalogs/add-catalog',
    addOciCatalog: '/vendor/catalogs/add-oci-catalog',
    editOciCatalog: '/vendor/catalogs/:ociCatalogId/edit-oci-catalog',
    assignCustomersToCatalog: '/vendor/catalogs/:catalogId/assign-customers',
    assignCustomersToOciCatalog:
      '/vendor/catalogs/:ociCatalogId/assign-customers-to-oci-catalog',
    catalogPreview: {
      root: '/vendor/catalogs/preview/:catalogVersionId',
      searchResult: '/vendor/catalogs/preview/:catalogVersionId/searchResult',
      articleDetails:
        '/vendor/catalogs/preview/:catalogVersionId/articles/:articleId',
    },
    mediaRepository: '/vendor/mediaRepository',
    uploadMedia: '/vendor/mediaRepository/upload-media',
    customers: '/vendor/customers',
    cartInfos: {
      root: '/vendor/announcements',
      addCartInfo: '/vendor/announcements/create',
      addDefaultCartInfo: '/vendor/announcements/create-default',
      editCartInfo: '/vendor/announcements/edit',
      editDefaultCartInfo: '/vendor/announcements/edit-default',
      assignCustomers: '/vendor/announcements/:announcementId/assign-customers',
    },
    myCompany: '/vendor/my-company',
    orderDataExchange: '/vendor/data-exchange',
    // T0DO: What about the URLs - it should be companyUserId, not userId
    // https://simplesystem.atlassian.net/browse/S2NG-3018
    users: '/vendor/users',
    addUser: '/vendor/users/add-new-user',
    editUser: '/vendor/users/:userId/edit',
    userDetails: '/vendor/users/:userId',
    priceLists: '/vendor/price-lists',
    addPriceList: '/vendor/add-price-list',
    assignCustomersToPriceList:
      '/vendor/price-lists/:priceListId/assign-customers',
    switchAccount: '/vendor/switch-account',
    articleNumbersLists: '/vendor/article-numbers-lists',
    addArticleNumbersList: '/vendor/add-article-numbers-list',
    assignCustomersToArticleNumbersList:
      '/vendor/article-numbers-lists/:articleNumbersListId/assign-customers',
    reports: '/vendor/reports',
    stockAndDeliveryLists: '/vendor/stock-and-delivery-lists',
    addStockAndDeliveryList: '/vendor/stock-and-delivery-lists/create',
    editStockAndDeliveryList:
      '/vendor/stock-and-delivery-lists/:stockAndDeliveryListId/edit',
  },
  backoffice: {
    root: '/backoffice',
    customers: '/backoffice/customers',
    vendors: '/backoffice/vendors',
    requests: '/backoffice/requests',
    erpOrderRoutingGroups: '/backoffice/erp-order-routing-groups',
    createErpOrderRoutingGroup: '/backoffice/erp-order-routing-groups/create',
    editErpOrderRoutingGroup:
      '/backoffice/erp-order-routing-groups/:groupId/edit',
    productCategories: '/backoffice/product-categories',
    createProductCategory: '/backoffice/product-categories/create',
    editProductCategory:
      '/backoffice/product-categories/:productCategoryId/edit',
  },
} as const

export type Paths = ValueOf<Flatten<typeof PATHS>>
export type PathForHistory =
  | Paths
  | (LocationDescriptorObject & { pathname: Paths })

import { Flex } from '@chakra-ui/react'

import { EClassInfo } from 'app/pages/common/eClassInfo'

import type { ArticleContentProps } from '../types'
import {
  CustomerArticleNumberText,
  DeliveryTime,
  StockInfo,
} from '../../ArticleTexts'

export const ListViewArticleDetails = ({
  article,
}: Pick<ArticleContentProps, 'article'>) => (
  <>
    <Flex flex={1} flexDirection="column" gap="4px">
      <CustomerArticleNumberText
        customerArticleNumber={article.customerArticleNumber}
      />

      <EClassInfo eclass={article.eclass} />
    </Flex>

    <Flex flex={1} flexDirection="column">
      <DeliveryTime
        deliveryTime={article.deliveryTime}
        deliveryInfo={article.deliveryInfo}
      />
      <StockInfo stockInfo={article.stockInfo} />
    </Flex>
  </>
)
